import Vue from 'vue/dist/vue.esm'
import App from '../app.vue'
import { start } from './sidebar.js'
import VueAxios from 'vue-axios'
import { securedAxiosInstance, plainAxiosInstance } from './axios/index.js'

document.addEventListener("DOMContentLoaded", function () {
  // console.log("ACTIVITY");
});
Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance
})
console.log('alan here in activity.js')
document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    start,
    securedAxiosInstance,
    plainAxiosInstance,
    el: '#act_feed',
    data: () => {
      return {
        message: "",
        varName: null
      }
    },
    components: { App }
  })
  console.log(app)
})
